html,
body {
  margin: 0 auto;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  height: 100%;
  max-width: 400px;
}

.logo_wrap .logo {
  background-image: url(https://cdn.0br1.io/img/logos/logo_192x192.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 10px auto;
  border: 0;
}

/* Header 영역 */
.header_cd {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 2;
  max-width: 400px;
}

/* Main 영역 */
.main_cd {
  width: 100%;
  height: calc(100% + 60px + 50px);
  overflow: auto;
  z-index: 1;
}

.default_info_title {
  height: 48px;
  line-height: 48px;
  text-align: left;
  color: #646464;
  font-weight: 600;
  font-size: 15px;
  margin-left: 20px;
}

.invalid_order {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  line-height: 20px;
  text-align: center;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
  padding-bottom: 20px;
  line-height: 40px;
}

.saved_info {
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 15px;
  color: #ff6700;
  padding-left: 20px;
  padding-right: 20px;
}

.notice_desc_font {
  font-size: 15px;
  color: #ff6700;
}

.notice_title img {
  width: 24px;
  height: 24px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table_row {
  display: flex;
  border: 0.8px solid #cdcdcd;
}

.table_title {
  min-width: 130px;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  font-size: 15px;
  font-weight: 600;
  color: #737373;
  border-right: 0.8px solid #cdcdcd;
}

.table_data {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
  font-size: 15px;
  font-weight: 600;
  color: #737373;
}

/* Footer 영역 */
.footer_cd {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0px;
  position: fixed;
  padding-bottom: 20px;
  background: white;
  width: calc(100% - 40px);
  z-index: 2;
}

.save_button_enabled {
  width: 100%;
  height: 50px;
  background-color: #fbe2d5;
  color: #ed702e;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
}

.save_button_disabled {
  width: 100%;
  height: 50px;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  border: 0;
  background-color: #f6f6f6;
  color: #c7c7c7;
}

.change_collection_date_title {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  height: 40px;
  font-weight: 600;
  color: #646464;
  border-bottom: 1px solid #c7c7cc;
}

.target_date_label {
  margin-left: 20px;
  color: #737373;
}

.collection_date_end_title {
  margin-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 28px;
  line-height: 45.15px;
  color: #333333;
}

.table_desc {
  margin-top: 10px;
}

.collection_date_end_content {
  padding-left: 10px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #646464;
}

.collection_date_end_content_point {
  padding-left: 20px;
  padding-right: 0px;
  align-content: flex-start;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #646464;
}
